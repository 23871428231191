import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Layout, { Section } from '../components/Layout';
import { Seo } from '../components/Seo';
import { ContentfulBlogpostQueryNode } from '../contentful-query';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES, Inline, Block } from '@contentful/rich-text-types';
import { BlogMdx } from './__mdx.css';
import SmartLink from '../components/Link';
import Author, { ContentfulAuthor } from '../components/Author';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import { enGB, de } from 'date-fns/locale';
import { format } from 'date-fns';
import { theme } from '../theme.css';
import { Text, Title } from '../components/Text';
import { HeaderCard } from './__contentfulBlog.css';
import { sprinkles } from '../sprinkles.css';
import { Section as SectionRecipe } from '../components/Layout.css';
import { ButtonRecipe } from '../components/Button.css';

const formatDate = (isoDate: string, language: string) => {
	const date = new Date(isoDate);
	switch (language) {
		case 'de':
			return format(date, 'dd. MMMM yyyy', { locale: de });
		case 'en':
			return format(date, 'MMMM dd, yyyy', { locale: enGB });
		default:
			throw new Error(`Unknown language ${language}`);
	}
}

const EmbeddedAssetBlock = ({ node, references }: { node: Block | Inline, references: any[] }) => {
	const ref = references.find(ref => ref.contentful_id === node.data.target.sys.id);
	if (!ref) return null;
	switch(ref.__typename) {
		case 'ContentfulAsset':
			switch(ref.mimeType) {
				case 'image/jpeg':
				case 'image/png':
					return <figure style={{ gap: theme.space.medium, margin: 0 }} className={SectionRecipe({
						width: 'fill',
						direction: 'vertical',
						align: 'stretch',
					})}>
						<GatsbyImage image={ref.gatsbyImageData} alt={ref.title} style={{ borderRadius: theme.radius.medium, alignSelf: "stretch", maxHeight: '500px' }}/>
						{ref.description && <figcaption><Text variant="secondary">{ref.description}</Text></figcaption>}
					</figure>;
				default:
					throw new Error(`Unsupported embedded asset type ${ref.mimeType}`);
			}
		default:
			throw new Error(`Unknown embedded asset type ${ref.__typename}`);

	}
}

const EntryHyperlink = ({ node, children, references }: { node: Inline | Block, children: React.ReactNode, references: any[] }) => {
	console.log(references, node.data.target.sys.id);
	const ref = references.find(ref => ref.contentful_id === node.data.target.sys.id);
	if (!ref) return null;
	switch(ref.__typename) {
		case 'ContentfulBlogPost':
			return <SmartLink to={`/blog/${ref.slug}`}>{children}</SmartLink>;
		default:
			return <span>{children}</span>;
	}	
};

const contentfulDocumentRedererOptions: (references: any[]) => Options = (references) => ( {
	renderNode: {
		[BLOCKS.HR]: () => <hr style={{ alignSelf: 'stretch', marginLeft:0, marginRight:0 }} />,
		[INLINES.HYPERLINK]: (node, children) => <SmartLink to={node.data.uri}>{children}</SmartLink>,
		[INLINES.ENTRY_HYPERLINK]: (node, children) => <EntryHyperlink node={node} references={references}>{children}</EntryHyperlink>,
		[BLOCKS.EMBEDDED_ASSET]: (node) => <EmbeddedAssetBlock node={node} references={references} />,
	}
});

const ContentfulBlogpostPage = ({
	pageContext: { content, path },
}: {
	pageContext: {
		content: ContentfulBlogpostQueryNode;
		path: string;
	};
}) => {
	const { t, i18n: { language } } = useTranslation('BlogTeaser');
	return (
		<Layout padding={false}>
			<Section width="fill">
				<Section width="fill" className={HeaderCard}>
					<Section width="wide" padding direction="vertical" align="center" gap="large">
						<SmartLink
							to="/knowledge"
							style={{ alignSelf: 'flex-start' }}
						>
							<Text white variant="secondary">← {t('BackButtonText')}</Text>
						</SmartLink>
						<h1 className={sprinkles({ color: 'white', textAlign: 'center', margin: "none", marginBottom: "medium" })}>{content.title}</h1>
						<ContentfulAuthor white name={content.author.name} avatar={content.author.image.gatsbyImageData} mailto={content.author.mailto} position={content.author.position} />
						<Text white variant="secondary">{formatDate(content.updatedAt, language)}  · {content.readTimeMin} {t('Minutes')} {t('ReadTime')}</Text>
					</Section>
				</Section>
				<Section padding width="wide" gap="none" align="stretch" className={sprinkles({ marginTop: 'giantNeg' })}>
					<GatsbyImage className={sprinkles({ boxShadow: "elevated", borderRadius: {
						mobile: 'medium',
						tablet: 'large',
					}, maxHeight: 'five' })} image={content.image.gatsbyImageData} alt={content.image.title} />
				</Section>
				<Section padding className={BlogMdx} direction="vertical" align="stretch" gap="large">
					{documentToReactComponents(
						JSON.parse(content.text.raw),
						contentfulDocumentRedererOptions(content.text.references),
					)}
					<SmartLink
						to="/knowledge"
						className={ButtonRecipe({
							size: 'medium',
							variant: 'secondaryText',
							wrap: 'yes',
						})}
						style={{ alignSelf: 'flex-start' }}
					>
						← {t('BackButtonText')}
					</SmartLink>
				</Section>
			</Section>
		</Layout>
	);
};

export const Head = ({
	pageContext: { content, path },
}: {
	pageContext: {
		content: ContentfulBlogpostQueryNode;
		path: string;
	};
}) => {
	return (
		<Seo
			title={content.title}
			description={content.metaDescription}
			image={content.image.url}
			pathname={path}
		/>
	);
};

export default ContentfulBlogpostPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		avatars: allFile(
			filter: { sourceInstanceName: { eq: "images" }, relativePath: { glob: "team/*" } }
		) {
			edges {
				node {
					relativePath
					name
					childImageSharp {
						gatsbyImageData(width: 128, height: 128, placeholder: BLURRED)
					}
				}
			}
		}
	}
`;
