import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { sprinkles } from '../sprinkles.css';
import { ButtonRecipe } from './Button.css';
import { LinkedIn, Mail } from './Icon';
import { Section } from './Layout';
import { Text, Title } from './Text';

const Author = ({
	nickName,
	avatars,
	mailto,
}: {
	nickName: string;
	mailto?: string;
	avatars:
		| {
				edges: {
					node: {
						name: string;
						childImageSharp: { gatsbyImageData: IGatsbyImageData };
					};
				}[];
		  }
		| IGatsbyImageData;
}) => {
	const image = (avatars as any).edges
		? (avatars as any).edges.find((e: any) => e.node.name === nickName)?.node
				.childImageSharp.gatsbyImageData
		: avatars;
	if (!image) throw new Error();
	const { t } = useTranslation('TeamPage', { keyPrefix: nickName });
	return (
		<Link className={ButtonRecipe({ size: 'large' })} to={'/team/#' + nickName}>
			<Section
				align="start"
				direction="horizontal"
				gap="medium"
				wrap={false}
				justify="right"
			>
				<GatsbyImage
					style={{ borderRadius: '256px', height: '100%' }}
					image={image}
					alt={t('Name')}
				/>
				<Section align="start" justify="center" gap="tiny">
					<Title level="paragraph">{t('Name')}</Title>
					<Text variant="primary" wrap>
						{t('Position')}
					</Text>
					<Section direction="horizontal" justify="left">
						<a
							style={{ lineHeight: 1 }}
							href={t('Linkedin')}
							target="_blank"
							rel="noreferrer"
						>
							<LinkedIn className={sprinkles({ fill: 'textLight' })} />
						</a>
						<a style={{ lineHeight: 1 }} href={mailto ?? 'mailto:' + t('Email')}>
							<Mail className={sprinkles({ fill: 'textLight' })} />
						</a>
					</Section>
				</Section>
			</Section>
		</Link>
	);
};

export const ContentfulAuthor = ({
	name,
	avatar,
	linkedin,
	mailto,
	position,
	white,
}: {
	name: string;
	avatar: IGatsbyImageData;
	linkedin?: string;
	mailto?: string;
	position: string;
	white?: boolean;
}) => 
			(<Section
				align="start"
				direction="horizontal"
				gap="medium"
				wrap={false}
				justify="center"
			>
				<GatsbyImage
					image={avatar}
					alt={name}
					style={{ border: '2px white dashed', borderRadius: '256px' }}
				/>
				<Section align="start" width="auto" justify="center" gap="tiny">
					<Text white={white}>{name}</Text>
					<Text variant="secondary" wrap white={white}>
						{position}
					</Text>
					<Section direction="horizontal" justify="left">
						{linkedin && <a
							style={{ lineHeight: 1 }}
							href={linkedin}
							target="_blank"
							rel="noreferrer"
						>
							<LinkedIn className={sprinkles({ fill: white ? 'white' : 'textLight' })} />
						</a>}
						{mailto && <a style={{ lineHeight: 1 }} href={mailto}>
							<Mail className={sprinkles({ fill: white ? 'white' : 'textLight' })} />
						</a>}
					</Section>
				</Section>
			</Section>);

export default Author;
